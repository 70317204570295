import logo from "./logo.svg";
import "./App.css";
import "./App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Inicio from "./components/inicio/Inicio";
import UsuariosNuevos from "./components/usunuevos/UsuariosNuevos";
import Fichas from "./components/fichas/Fichas";
import AgregarFicha from "./components/fichas/agregar/AgregarFicha";
import ModalProvider from "./context/modal/ModalProvider";
import ModificarFicha from "./components/fichas/modificar/ModificarFicha";
import SubiraFicha from "./components/fichas/subir/SubiraFicha";
import EditarFicha from "./components/fichas/editar/EditarFicha";
import Usuarios from "./components/usuarios/Usuarios";
import EditarUsuario from "./components/usuarios/editar/EditarUsuario";
import AgregarUsuario from "./components/usuarios/agregar/AgregarUsuario";
import ModificarUsuario from "./components/usuarios/modificar/ModificarUsuario";
import SubirArchivo from "./components/subir/SubirArchivo";
import Propietarios from "./components/Propietarios";
import Inquilinos from "./components/Inquilinos";
import GastosComunes from "./components/GastosComunes";
import CambiarContra from "./components/CambiarContra";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import AuthProvider from "./components/auth/AuthProvider";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiProvider, { ApiContext } from "./context/api/ApiProvider";
import LoadContext from "./context/load/LoadProvider";
import UsuNuevosProvider from "./context/usuNuevos/UsuNuevosProvider";
import { useEffect } from "react";
import axios from "axios";
import InquilinoPago from "./components/InquilinoPago";
import GastosPago from "./components/GastosPago";

function App() {
  useEffect(() => {
    axios.defaults.withCredentials = true;
  }, []);
  return (
    <div className="App">
      <ToastContainer />
      <LoadContext>
        <ModalProvider>
          <AuthProvider>
            <BrowserRouter>
              <ApiProvider>
                <UsuNuevosProvider>
                  <Routes>
                    <Route index element={<Login />} />
                    <Route path={"register"} element={<Register />} />
                    <Route path={"/inicio"} element={<Inicio />}>
                      <Route path={"subir"} element={<SubirArchivo />} />
                      <Route path={"propietario"} element={<Propietarios />} />
                      <Route path={"inquilino"} element={<Inquilinos />} />
                      <Route
                        path={"inquilinoPago/:ficha/:fecha/:comprobante"}
                        element={<InquilinoPago />}
                      />
                      <Route
                        path={"gastoscomunesPago/:ficha/:tipo"}
                        element={<GastosPago />}
                      />
                      <Route path={"gastos"} element={<GastosComunes />} />
                      <Route path={"contra"} element={<CambiarContra />} />
                      <Route
                        path={"usuariosNuevos"}
                        element={<UsuariosNuevos />}
                      ></Route>

                      <Route path={"fichas"} element={<Fichas />}>
                        <Route path={"agregar"} element={<AgregarFicha />} />
                        <Route
                          path={":id/modificar"}
                          element={<ModificarFicha />}
                        />
                        <Route path={":id/subir"} element={<SubiraFicha />} />
                        <Route
                          path={":propId/editar"}
                          element={<EditarFicha />}
                        />
                      </Route>
                      <Route path={"usuarios"} element={<Usuarios />}>
                        <Route
                          path={":userID/editar"}
                          element={<EditarUsuario />}
                        />
                        <Route
                          path={":userID/modificar"}
                          element={<ModificarUsuario />}
                        />
                        <Route path={"agregar"} element={<AgregarUsuario />} />
                      </Route>
                    </Route>
                  </Routes>
                </UsuNuevosProvider>
              </ApiProvider>
            </BrowserRouter>
          </AuthProvider>
        </ModalProvider>
      </LoadContext>
    </div>
  );
}

export default App;
