import {
  faCheck,
  faClose,
  faDollar,
  faExclamationTriangle,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

const NotificacionPago = ({ estado }) => {
  const [text, settext] = useState("");
  const [icon, seticon] = useState("");
  const [backgroundColor, setbackgroundColor] = useState("");
  const [primaryColor, setprimaryColor] = useState("");
  useEffect(() => {
    switch (estado) {
      case 0:
        settext("Recibo Aún no Pago");
        seticon(faDollar);
        setbackgroundColor("#dbd0bd");
        setprimaryColor("#966715");
        break;
      case 3:
        settext("Pago Pendiente de Confirmación");
        seticon(faHourglass);
        setbackgroundColor("#dbdbbd");
        setprimaryColor("#b5a407");
        break;
      case 2:
        settext("Pago Rechazado, por favor reintente");
        seticon(faClose);
        setbackgroundColor("#dbbdbf");
        setprimaryColor("#69050e");
        break;
      case 1:
        settext("Recibo Pago");
        seticon(faCheck);
        setbackgroundColor("#c2dbbd");
        setprimaryColor("#17570a");
        break;
      case 4:
        settext("Recibo Vencido, contacte a la administración");
        seticon(faExclamationTriangle);
        setbackgroundColor("#dbbdbf");
        setprimaryColor("#69050e");
        break;
    }
  }, [estado]);
  return (
    <div className="m-3 d-flex align-items-center justify-content-center w-100">
      <div
        className="d-flex flex-row align-items-center justify-content-center w-75 rounded-4 p-2 "
        style={{
          backgroundColor,
          borderLeft: "3px solid " + primaryColor,
          height: "5vh",
          minHeight: "40px",
          maxHeight: "50px",
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          className="me-2"
          style={{ color: primaryColor }}
        />
        <p
          className="d-flex flex-row align-items-center justify-content-center m-0 h-100"
          style={{ fontWeight: "500", color: primaryColor }}
        >
          {text}
        </p>
      </div>
    </div>
  );
};

export default NotificacionPago;
