import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styles from "../context/modal/Modal.module.css";
import { LoadContext } from "../context/load/LoadProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiContext } from "../context/api/ApiProvider";
import {
  faCheckCircle,
  faCircleXmark,
  faClose,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";

const InquilinoPago = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { ficha, fecha, comprobante } = useParams();
  let [text, setText] = useState("");
  let [title, setTitle] = useState("");
  let [icon, setIcon] = useState("");
  let [color, setColor] = useState("");
  const { setIsLoading } = useContext(LoadContext);
  const navigate = useNavigate();
  const api = useContext(ApiContext);

  useEffect(() => {
    setIsLoading(true);
    api.get(
      "/inquilino/estadoRecibo/" + ficha + "/" + fecha + "/" + comprobante,
      (res) => {
        setIsLoading(false);
        if (res.data.error) {
          navigate("/inicio/inquilinos");
        }
        switch (res.data.estado) {
          case 0:
            setIcon(faHourglass);
            setText(
              "La transacción no se cumplió, por favor vuelva a intentarlo."
            );
            setTitle("Transacción no completada");
            setColor("orange");
            break;
          case 1:
            setIcon(faCheckCircle);
            setText("El pago fue realizado con exito.");
            setTitle("Pago Exitoso");
            setColor("green");
            break;
          case 2:
            setIcon(faCircleXmark);
            setText(
              "El pago fue rechazado. Por favor, intente de nuevo más tarde."
            );
            setTitle("Pago Rechazado");
            setColor("red");
            break;
          case 3:
            setIcon(faHourglass);
            setText("El pago se encuentra pendiente de confirmación.");
            setTitle("Pago Pendiente");
            setColor("orange");
            break;
        }
      }
    );
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        width: "100vw",
        height: "100vh",
        top: 0,
        left: 0,
        backdropFilter: "blur(15px)",
      }}
      className="d-flex align-items-center justify-content-center"
    >
      <div className={styles.modal}>
        <span className={"w-100 d-flex justify-content-end pe-4 pt-3"}>
          <FontAwesomeIcon
            icon={faClose}
            onClick={() => navigate("/inicio/inquilino")}
            style={{ fontSize: "1.5em", cursor: "pointer" }}
          />
        </span>
        <h2>{title}</h2>
        <div className={styles.hr}></div>
        <div className="d-flex flex-column align-items-center w-100 p-3">
          <FontAwesomeIcon icon={icon} style={{ fontSize: "3em", color }} />
          <p className="m-3 " style={{ fontSize: "1.3em" }}>
            {text}
          </p>
        </div>
        <div className={styles.hr}></div>
        <div className={"d-flex flex-row p-2 justify-content-end"}>
          <button
            className={"btn btn-success me-2"}
            onClick={() => {
              navigate("/inicio/inquilino");
            }}
          >
            Confirmar
          </button>
        </div>
      </div>
    </div>
  );
};

export default InquilinoPago;
