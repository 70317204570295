import {
  faDownload,
  faHourglass,
  faMoneyBill,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../context/api/ApiProvider";
import { AuthContext } from "./auth/AuthProvider";
import Notificacion from "./usuarios/utils/NotificacionPago";
import NotificacionPago from "./usuarios/utils/NotificacionPago";
import { Outlet } from "react-router-dom";

const Inquilinos = () => {
  const api = useContext(ApiContext);
  const [fichas, setFichas] = useState([]);
  const [selectedFicha, setSelectedFicha] = useState("");
  const [fechas, setFechas] = useState([]);
  const [selectedFecha, setSelectedFecha] = useState("");
  const [selectedFechaDisponible, setSelectedFechaDisponible] = useState(false);
  const [selectedFechaEstado, setSelectedFechaEstado] = useState("");
  const [selectedComprobante, setSelectedComprobante] = useState("");
  const [cuentas, setCuentas] = useState({ pesos: false, dolares: false });
  const { user } = useContext(AuthContext);

  useEffect(() => {
    updateFichas();
  }, []);

  function updateFichas() {
    api.get("/inquilino/fichas", (res) => {
      setFichas(res.data.fichas);
      if (res.data.fichas) {
        setSelectedFicha(res.data.fichas[0].Codigo);
        setFechas(res.data.fichas[0].fechas);
        if (res.data.fichas[0].fechas.length > 0) {
          setSelectedFecha(res.data.fichas[0].fechas[0].Fecha_ID);
          setSelectedFechaDisponible(res.data.fichas[0].fechas[0].disponible);
          setSelectedFechaEstado(res.data.fichas[0].fechas[0].Estado);
          setSelectedComprobante(res.data.fichas[0].fechas[0].Comprobante);
        }
        setCuentas(res.data.fichas[0].cuentas);
      }
    });
  }

  function downloadCuenta(tipo) {
    api.download(
      "/inquilino/" + selectedFicha + "/descargarCuenta/" + tipo,
      (res) => {
        let url = window.URL.createObjectURL(new Blob([res.data]));
        let link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "cuenta" + tipo + selectedFicha + ".pdf");
        link.click();
      }
    );
  }

  function downloadRecibo() {
    if (selectedFecha && selectedFicha && selectedFechaDisponible) {
      api.download(
        "/inquilino/" +
          selectedFicha +
          "/descargarRecibo/" +
          selectedFecha +
          "/" +
          selectedComprobante,
        (res) => {
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            "recibo" +
              selectedFecha +
              selectedFicha +
              selectedComprobante +
              ".pdf"
          );
          link.click();
        }
      );
    }
  }

  function handlePay() {
    if (
      user.AdmPagos !== 1 ||
      selectedFechaEstado == 3 ||
      selectedFechaEstado == 1 ||
      selectedFechaEstado == 4 ||
      !selectedFecha
    ) {
      return;
    }
    api.post(
      "/inquilino/" + selectedFicha + "/pagar",
      {
        Fecha: selectedFecha,
        Ficha: selectedFicha,
        Comprobante: selectedComprobante,
      },
      (res) => {
        if (res.data.success && res.data.redirect) {
          window.location.href = res.data.redirect;
        }
      }
    );
  }

  return (
    <div className="col-12 d-flex flex-column align-items-center justify-content-center pt-3">
      <h3 className="text-center">Descargar archivos de Inquilino</h3>
      <hr className="hr w-100" />
      <div className="col-12 col-md-8 col-lg-4 p-1 d-flex flex-column align-items-center">
        {" "}
        <label className="h5 pt-3">Finca</label>
        <select
          className="form-control"
          value={selectedFicha}
          onChange={(e) => {
            setSelectedFicha(e.target.value);
            //cambio cuentas
            setCuentas(
              fichas.filter((ficha) => ficha.Codigo == e.target.value)[0]
                .cuentas
            );

            //cambio fechas
            setFechas(
              fichas.filter((ficha) => ficha.Codigo == e.target.value)[0].fechas
            );
            let fechasFicha = fichas.filter(
              (ficha) => ficha.Codigo == e.target.value
            )[0].fechas;
            if (fechasFicha.length > 0) {
              setSelectedFecha(fechasFicha[0].Fecha_ID);
              setSelectedFechaDisponible(fechasFicha[0].disponible);
              setSelectedFechaEstado(fechasFicha[0].Estado);
              setSelectedComprobante(fechasFicha[0].Comprobante);
            } else {
              setSelectedFecha("");
              setSelectedFechaDisponible(false);
              setSelectedFechaEstado("");
              setSelectedComprobante("");
            }
          }}
        >
          {fichas
            ? fichas.map((ficha) => {
                return (
                  <option key={ficha.Propiedad_ID} value={ficha.Codigo}>
                    {ficha.Direccion}
                  </option>
                );
              })
            : ""}
        </select>
        <label className="h5 pt-3">Fecha</label>
        <select
          className="form-control"
          value={selectedFecha + " " + selectedComprobante}
          onChange={(e) => {
            let fecha = e.target.value.split(" ")[0];
            let comprobante = e.target.value.split(" ")[1];
            setSelectedFecha(fecha);
            setSelectedFechaDisponible(
              fechas.find(
                (f) => f.Fecha_ID == fecha && f.Comprobante == comprobante
              ).disponible
            );
            setSelectedFechaEstado(
              fechas.find(
                (f) => f.Fecha_ID == fecha && f.Comprobante == comprobante
              ).Estado
            );
            setSelectedComprobante(comprobante);
          }}
        >
          {fechas
            ? fechas.map((fecha) => {
                if (user.AdmPagos) {
                  let estadoTexto = fecha.Estado;
                  let estadoColor = fecha.Estado;
                  switch (estadoTexto) {
                    case 0:
                      estadoTexto = "No Pago";
                      estadoColor = "orange";
                      break;
                    case 3:
                      estadoTexto = "Pendiente";
                      estadoColor = "yellow";
                      break;
                    case 1:
                      estadoTexto = "Pago";
                      estadoColor = "green";
                      break;
                    case 2:
                      estadoTexto = "Rechazado";
                      estadoColor = "red";
                      break;
                    case 4:
                      estadoTexto = "Vencido";
                      estadoColor = "red";
                      break;
                  }
                  return (
                    <option
                      key={fecha.Fecha_ID}
                      value={fecha.Fecha_ID + " " + fecha.Comprobante}
                      style={{ color: estadoColor }}
                    >
                      {fecha.Fecha +
                        ` (${fecha.Comprobante}) ` +
                        " - " +
                        estadoTexto}
                    </option>
                  );
                } else {
                  return (
                    <option
                      key={fecha.Fecha_ID}
                      value={fecha.Fecha_ID + " " + fecha.Comprobante}
                    >
                      {fecha.Fecha + ` (${fecha.Comprobante})`}
                    </option>
                  );
                }
              })
            : ""}
        </select>
        {fechas.length == 0 ? (
          <p
            className="p text-success mt-1"
            style={{ fontWeight: "bold", fontStyle: "italic" }}
          >
            No hay recibos pendientes
          </p>
        ) : (
          ""
        )}
        {user.AdmPagos == 1 && selectedFechaEstado !== "" ? (
          <NotificacionPago estado={selectedFechaEstado} />
        ) : (
          ""
        )}
        <hr className="hr w-100" />
        <button
          className="btn btn-success w-100 mt-3 mb-3"
          disabled={
            !(selectedFecha && selectedFicha && selectedFechaDisponible)
          }
          onClick={downloadRecibo}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Recibo
        </button>
        {user.AdmPagos == 1 &&
        (selectedFechaEstado === 0 || selectedFechaEstado === 2) ? (
          <button
            className="btn btn-success w-100 mt-3 mb-3 p-3"
            disabled={!(selectedFecha && selectedFicha)}
            onClick={handlePay}
          >
            {" "}
            <FontAwesomeIcon icon={faMoneyBill} /> Pagar
          </button>
        ) : (
          ""
        )}
        <hr className="hr w-100" />
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!cuentas.pesos}
          onClick={() => {
            downloadCuenta("pesos");
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Cuenta Pesos
        </button>
        <button
          className="btn btn-success w-100 mt-5"
          disabled={!cuentas.dolares}
          onClick={() => {
            downloadCuenta("dolares");
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Descargar Cuenta Dolares
        </button>
      </div>
    </div>
  );
};

export default Inquilinos;
